import React from 'react';

import Page from '../components/Page';
import SEO from '../components/seo';
import NewsNav from '../components/News/NewsNav';
import NewsContent from '../components/News/NewsContent';
import NewsFooter from '../components/News/NewsFooter';
import Heading from '../components/Heading';
import P from '../components/P';

function NotFoundPage() {
  return (
    <Page>
      <SEO title="Page not found" />

      <NewsNav />

      <NewsContent>
        <Heading withSpacing>Oops! That page can’t be found.</Heading>
        <P>It looks like nothing exists here. Tough luck!</P>
      </NewsContent>

      <NewsFooter />
    </Page>
  );
}

export default NotFoundPage;
